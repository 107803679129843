import React, { useEffect, useState } from 'react';

export default function LoadScripts() {
    const [loading, setLoading] = useState(true); // State to manage the preloader visibility

    useEffect(() => {
      window.scrollTo(0, 0);
        const scripts = [
          '/js/jquery.min.js',
          '/js/jquery.bootstrap.js',
          '/js/jquery.magnific-popup.js',
          '/js/jquery.owl.carousel.js',
          '/js/jquery.ion.rangeSlider.js',
          '/js/jquery.isotope.pkgd.js',
          '/js/main.js',
        ];

        const loadScriptsSequentially = async () => {
          for (const src of scripts) {
            await loadScript(src);
          }

          // Ensure the preloader runs for at least 2 seconds
          const delay = Math.max(300 - (Date.now() - startTime), 0);

          setTimeout(() => {
            setLoading(false); // Hide preloader after all scripts are loaded and minimum delay is met
          }, delay);
        };

        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = false; // Ensures the order of execution
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };

        const startTime = Date.now(); // Record the start time when script loading begins
        loadScriptsSequentially();

        // Cleanup function to remove scripts when component unmounts
        return () => {
          scripts.forEach((src) => {
            const script = document.querySelector(`script[src="${src}"]`);
            if (script) {
              document.body.removeChild(script);
            }
          });
        };
      }, []); // Empty dependency array to run only once on mount

  return (
    <>
      {loading && <div className="page-loader" id="preloader"></div>}
      {/* The rest of your component can go here */}
    </>
  );
}
